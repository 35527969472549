import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, MutableRefObject, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { CustomText, LessonType, SentenceType, StatusType, TaskType } from "App.types";
import { HistoryEditor, withHistory } from "slate-history";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { Button, Popconfirm } from "antd";
import cx from "classnames";
import { getCompletedSlate, withCustomLogic } from "App.helpers";
import { BulbTwoTone, CheckOutlined } from "@ant-design/icons";
import SentenceLeaf from "../SentenceLeaf";
import { useAudioTranscript } from "../Helpers/useAudioTranscript";
import styles from "../SpeechLessonTask.module.scss";
import { useOnCheck } from "../Helpers/useOnCheck";
import { usePressEnter } from "../Helpers/usePressEnter";
import { delay } from "lodash";
import { useTranscripts } from "Hooks/useTranscripts";
import API from "Api";

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const initialValue = [{ children: [{ text: " " }] }];

type Props = PropsWithChildren & {
  active: boolean;
  sentence: SentenceType;
  activeType: TaskType;
  lesson: LessonType;
  // setTries?: Dispatch<SetStateAction<number>>;
  audio?: HTMLAudioElement;
  showAnswer?: boolean;
  autoHeight?: boolean;
  onNext: Function;
  onCheckRef: MutableRefObject<any>;
};

const ScrollerTaskTranslate: FC<Props> = ({
  sentence,
  sentence: { text, tags, translate, phrases },
  lesson,
  children,
  audio,
  onNext,
  onCheckRef,
  active,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const setInitialState = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];

    Transforms.insertNodes(editor, initialValue, { select: true });
  }, [editor]);

  useEffect(() => {
    if (active) {
      try {
        ReactEditor.focus(editor);
      } catch (e) {}
    }
  }, [editor, active]);

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setInitialState();
    setStatus(StatusType.Empty);
  }, [sentence.id, editor, setInitialState]);

  const transcripts = useTranscripts({ sentence });

  // audio transcript
  useAudioTranscript({ transcripts, setActiveLeaf, sentence, audio });

  const onCheck = useOnCheck({
    editor,
    activeType: TaskType.Translate,
    text,
    tags,
    phrases,
    id: sentence.id,
    lesson,
    alternatives: sentence.alternatives,
    //notifyApi: () =>{},
    onComplete: () => {
      setStatus(StatusType.Completed);
      onNext();
    },
    setStatus,
  });

  useEffect(() => {
    onCheckRef.current = onCheck;
  }, [onCheck, onCheckRef]);

  usePressEnter({
    isCompleted: status === StatusType.Completed,
    onCheck,
  });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceLeaf
        underlined={props.leaf.audioIdx === activeLeaf}
        showErrors={![StatusType.Completed, StatusType.Editing].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const onHintAnswer = async () => {
    const text = editor.children[0]?.children?.map((el: CustomText) => el.text).join("");
    API.event.save({ text, type: "hint", task: TaskType.Translate, lesson: { id: lesson.id }, sentence });

    const status = await onCheck(text, true);
    const wrongIdx = editor.children[0]?.children.findIndex((child: CustomText) => child.status === "wrong");
    if (wrongIdx >= 0) {
      Transforms.insertText(editor, "", { at: [0, wrongIdx] });
      await onCheck("", true);
    }

    if (status) {
      const hasError = editor.children[0]?.children.every((el: CustomText, idx: number) => {
        if (["missed", "lemma", "typo"].includes(el.status || "")) {
          // setTry(true);
          Transforms.setNodes(editor, { status: "success" }, { at: [0, idx] });
          Transforms.insertText(editor, el.word || el.answer || "", { at: [0, idx] });
          setActiveLeaf(el.idx || null);
          delay(() => setActiveLeaf(null), 2000);
          return false;
        }

        if (el.status === "order") {
          editor.children.forEach(() => {
            Transforms.delete(editor, { at: [0] });
          });

          editor.children = [];
          Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
          return false;
        }
        return true;
      });

      if (hasError) {
        // setTry();
      }
    }
  };

  return (
    <div className={cx(styles.speechLessonTask, { [styles.speechLessonTask__inActive]: false })}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: true })}>
          <div className={styles.slate_wrapper}>
            <Slate editor={editor} initialValue={initialValue}>
              <Editable
                onKeyDown={() => setStatus(StatusType.Editing)}
                className={styles.textArea}
                renderLeaf={renderLeaf}
                readOnly={status === StatusType.Completed}
              />
            </Slate>

            {status === StatusType.Completed ? (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CheckOutlined style={{ fontSize: 20, color: "green" }} />}
              />
            ) : (
              active && (
                <Popconfirm title={"Дать подсказку?"} onConfirm={onHintAnswer}>
                  <Button size={"small"} type={"text"} className={styles.btn__clear} icon={<BulbTwoTone />} />
                </Popconfirm>
              )
            )}
          </div>
          <div className={styles.translate}>{translate}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ScrollerTaskTranslate);
