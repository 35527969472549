import { useCallback, useContext, useEffect, useState } from "react";
import { SentenceType, SpeechResultType } from "App.types";
import { isEqualEnglishWords } from "App.helpers";
import { NotifyContext } from "App";
import { SkipTags } from "App.constants";
import usePrevious from "Hooks/usePrevious";
import { useSpeechWords } from "Hooks/useSpeechWords";

type Props = {
  enabled?: boolean;
  speechResults: SpeechResultType[] | undefined;
  sentence: SentenceType | undefined;
};

const WRONG_TRIES = 3;

export const useSpeechCheckRead = ({ enabled = true, speechResults, sentence }: Props) => {
  const [wrongCount, setWrongCount] = useState(0);
  const [wordIdx, setWordIdx] = useState<number>(0);
  const [completedTagIdx, setCompletedTagIdx] = useState<number>(-1);

  const { tags = [] } = sentence || { tags: [] };

  const reset = useCallback(() => {
    setCompletedTagIdx(-1);
    setWrongCount(0);
  }, []);

  useEffect(() => reset(), [reset]);

  useEffect(() => {
    setWrongCount(0);
    setCompletedTagIdx(-1);
    setWordIdx(0);
  }, [tags, setCompletedTagIdx]);

  const notifyApi = useContext(NotifyContext);

  useEffect(() => {
    if (SkipTags.includes(tags[completedTagIdx + 1]?.word)) {
      setCompletedTagIdx(completedTagIdx + 1);
    }
  }, [tags, completedTagIdx, setCompletedTagIdx]);

  const wordHomophones = useSpeechWords(sentence);

  useEffect(() => {
    setWordIdx(0);
  }, [speechResults]);

  useEffect(() => {
    setWrongCount(0);
  }, [completedTagIdx]);

  const lastTagIdx = usePrevious(completedTagIdx);
  useEffect(() => {
    if (lastTagIdx === completedTagIdx && wrongCount > WRONG_TRIES && enabled) {
      const activeTag = tags[completedTagIdx + 1];

      if (activeTag) {
        setCompletedTagIdx((prev) => prev + 1);
      }
      setWrongCount(0);
    }
  }, [tags, completedTagIdx, enabled, lastTagIdx, notifyApi, setCompletedTagIdx, wrongCount]);

  useEffect(() => {
    if (speechResults?.length && speechResults.some((r) => r.isFinal) && enabled) setWrongCount((prev) => prev + 1);
  }, [speechResults, enabled]);

  const lastResults = usePrevious(speechResults);

  useEffect(() => {
    if (
      (lastResults !== speechResults && wordIdx) ||
      completedTagIdx >= tags.length - 1 ||
      !speechResults?.length ||
      !enabled ||
      !speechResults.some((a) => a.words[wordIdx])
    )
      return;

    if (speechResults.some((result) => isEqualEnglishWords(result.transcript, sentence?.text))) {
      setCompletedTagIdx(Infinity);
    } else {
      speechResults.some((speechResult) => {
        const speechWord = speechResult.words[wordIdx]?.word;
        const activeTag = tags[completedTagIdx + 1];
        activeTag.word = activeTag.word.replaceAll("’", "'");
        if (!speechWord || !activeTag?.word) return false;

        console.log(
          "compare:",
          activeTag?.word,
          speechWord,
          isEqualEnglishWords(activeTag?.word, speechWord) || isEqualEnglishWords(speechWord, activeTag?.word),
        );

        // check what's => what is, ...etc
        const contractions = speechWord.split("'");
        if (
          contractions.length === 2 &&
          isEqualEnglishWords(activeTag.word, contractions[0]) &&
          isEqualEnglishWords(`'${contractions[1]}`, `${tags[completedTagIdx + 2]?.word}`)
        ) {
          setCompletedTagIdx((prev) => prev + 2);
          return true;
        }

        // check don't, can't, etc...
        if (
          isEqualEnglishWords(`${activeTag.word}${tags[completedTagIdx + 2]?.word}`, speechWord) ||
          isEqualEnglishWords(speechWord, `${activeTag.word}${tags[completedTagIdx + 2]?.word}`)
        ) {
          setCompletedTagIdx((prev) => prev + 2);
          return true;
        }

        if (isEqualEnglishWords(activeTag.word, speechWord) || isEqualEnglishWords(speechWord, activeTag?.word)) {
          setCompletedTagIdx((prev) => prev + 1);
          return true;
        }

        if (
          wordHomophones?.[activeTag.word.toLowerCase()]?.some(
            (word) => isEqualEnglishWords(word, speechWord) || isEqualEnglishWords(speechWord, word),
          )
        ) {
          setCompletedTagIdx((prev) => prev + 1);
          return true;
        }

        return false;
      });

      setWordIdx((prev) => prev + 1);
    }
  }, [wordHomophones, sentence, tags, speechResults, enabled, completedTagIdx, setCompletedTagIdx, wordIdx, lastResults]);

  return { reset, completedTagIdx, setCompletedTagIdx };
};
