import { SentenceTaskProps } from "../SentenceTask.type";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button, Flex, Modal } from "antd";
import API from "Api";
import styles from "./Conversation.module.scss";
import { useSuspenseQuery } from "@tanstack/react-query";
import PushAudioRecorder from "./PushAudioRecorder";
import TaskPanel from "Components/TaskPanel";
import { StatusType, TaskType } from "App.types";
import { SoundOutlined } from "@ant-design/icons";
import { STATIC_URL } from "App.constants";
import { encodeS3URI } from "App.helpers";
import { isMobile } from "react-device-detect";
import useVisualizer from "Components/useVisualizer";
import { polar } from "Components/useVisualizer/polar";
import cx from "classnames";
import AnimatedAlien from "Components/AnimatedAlien";
import useAlienIdle from "Components/AnimatedAlien/useAlienIdle";
import useAlienSpeak from "Components/AnimatedAlien/useAlienSpeak";

function toArrayBuffer(buffer: any) {
  const arrayBuffer = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(arrayBuffer);
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return arrayBuffer;
}

const Conversation: FC<SentenceTaskProps> = ({ sentence, lesson, onTaskComplete, onNext }) => {
  const [isStarted, setStarted] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<StatusType>(StatusType.Empty);
  // const [audioData, setAudioData] = useState<any>();

  const { linkedStoryId: storyId = 0, media, voice, markers } = sentence;

  const { data: topic } = useSuspenseQuery({
    queryKey: ["topic", storyId],
    queryFn: () => API.conversation.getTopic(storyId),
  });

  useEffect(() => {
    setShowDialog(!!topic);
  }, [topic]);

  const showAlien = markers.includes("lexoid");
  const { startIdle, stopIdle } = useAlienIdle();
  const { play: startSpeaking, stop: stopSpeaking } = useAlienSpeak();

  // const [form] = Form.useForm<{ message: string }>();
  const audioRef = useRef<HTMLMediaElement>(null);

  // @ts-ignore
  const [ReactAudioViz, initializeVisualizer] = useVisualizer(audioRef);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onplay = () => {
        setIsPlaying(true);
      };
      audioRef.current.onerror = () => setIsPlaying(false);
      audioRef.current.onended = () => setIsPlaying(false);
      audioRef.current.onpause = () => setIsPlaying(false);
    }
  }, []);

  const sendMessage = useCallback(
    (blob: Blob) => {
      if (!blob) {
        return setIsLoading(false);
      }

      const file = new File([blob], "record");

      const form = new FormData();
      form.append("file", file);
      voice && form.append("voice", voice);
      form.append("storyId", `${storyId}`);
      showAlien && form.append("alien", `${showAlien}`);

      setIsLoading(true);

      API.conversation
        .send(form)
        .then((data) => {
          const ab = toArrayBuffer(data.audio.data);
          const blob = new Blob([ab], { type: "audio/mp3" });
          if (audioRef.current) {
            if ("audioSession" in navigator) {
              // @ts-ignore
              navigator.audioSession.type = "playback";
            }
            audioRef.current.src = window.URL.createObjectURL(blob);
            audioRef.current.load();
            audioRef.current.play();
          }

          if (data.answer.includes("*completed*")) {
            setStatus(StatusType.Completed);
            onTaskComplete();
          }
        })
        .finally(() => setIsLoading(false));
    },
    [storyId, onTaskComplete, voice, showAlien],
  );

  useEffect(() => {
    if (isPlaying) {
      stopIdle();
      startSpeaking();
    } else if (showAlien) {
      stopSpeaking();
      startIdle();
    }
  }, [isPlaying, showAlien, startIdle, startSpeaking, stopIdle, stopSpeaking]);

  const start = useCallback(() => {
    initializeVisualizer();

    setIsLoading(true);
    API.conversation
      .start(storyId, voice)
      .then((data) => {
        const blob = new Blob([data], { type: "audio/wav" });
        if (audioRef.current) {
          audioRef.current.src = window.URL.createObjectURL(blob);
          audioRef.current.play();
          audioRef.current.addEventListener("ended", () => {
            setStarted(true);
          });
        }
      })
      .finally(() => setIsLoading(false));
  }, [storyId, voice, initializeVisualizer]);

  const onStartRecording = useCallback(() => {
    audioRef.current?.pause();
  }, []);

  return (
    <div className={styles.conversation}>
      {showAlien && <AnimatedAlien loading={isLoading} />}
      {media && !isMobile && (
        <div className={styles.imgContainer}>
          <img className={styles.picture} alt={""} src={`${STATIC_URL}/media/${encodeS3URI(media)}`} />
        </div>
      )}
      <audio ref={audioRef} />

      <Flex justify={"center"} className={styles.container}>
        <div className={cx(styles.audioViz, { [styles.audioViz__isPlaying]: isPlaying })}>
          <ReactAudioViz model={polar({ darkMode: false, binSize: 3, scale: 1 })} />
        </div>

        <div className={cx(styles.recorder, { [styles.recorder__disabled]: !isStarted || isLoading })}>
          <PushAudioRecorder
            disabled={!isStarted}
            onStart={onStartRecording}
            isPlaying={isPlaying}
            loading={isLoading}
            setBlob={sendMessage}
          />
        </div>
      </Flex>

      <Modal
        title={"Продолжить диалог?"}
        okText={"Да"}
        cancelText={"Начать заново"}
        onCancel={() =>
          topic &&
          API.conversation
            .deleteTopic(topic?.id)
            .then(() => setShowDialog(false))
            .then(() => setStarted(false))
        }
        open={showDialog}
        onOk={() => setShowDialog(false)}
      />

      <TaskPanel
        isCompleted={status === StatusType.Completed}
        task={TaskType.Conversation}
        sentId={sentence.id}
        lessonId={lesson.id}
        storyId={storyId}
        onNext={onNext}
        rightButton={
          <Button
            disabled={!isStarted}
            type={"primary"}
            onClick={() => {
              if ("audioSession" in navigator) {
                // @ts-ignore
                navigator.audioSession.type = "playback";
              }
              audioRef.current?.play();
            }}
            className={styles.btn__repeat}
            size={"large"}
            icon={<SoundOutlined />}
          />
        }
      >
        {!isStarted && (
          <Button size={"large"} type={"primary"} onClick={start}>
            start
          </Button>
        )}
      </TaskPanel>
    </div>
  );
};

export default Conversation;
