import { useCallback, useEffect, useRef, useState } from "react";
import { random } from "lodash";

const action = "color";

const useAlienColor = ({ color, duration } = { color: "white", duration: "1s" }) => {
  const [active, setActive] = useState(false);
  const [trigger, setTrigger] = useState<number>();

  const start = useCallback(() => setActive(true), []);
  const stop = useCallback(() => setActive(false), []);
  const disappear = useCallback(() => setTrigger(1), []);

  const timerRef = useRef<any>();

  const startTimer = useCallback(() => {
    disappear();
    timerRef.current = setTimeout(startTimer, random(10, 50) * 1000);
  }, [disappear]);

  useEffect(() => {
    if (active) {
      timerRef.current = setTimeout(startTimer, random(500, 10000));
    } else {
      setTrigger(undefined);
      clearTimeout(timerRef.current);
    }
  }, [active, startTimer]);

  useEffect(() => {
    const id = "rightHead";

    const parent = document.getElementById(id);

    if (trigger) {
      const prev = document.getElementById(`${id}-${action}`);
      if (prev) parent?.removeChild(prev);

      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-${action}`);

      anim.setAttribute("attributeName", "fill");
      anim.setAttribute("dur", duration);
      anim.setAttribute("to", color);
      anim.setAttribute("fill", "freeze");

      parent?.appendChild(anim);
      anim.beginElement();

      const prevAppear = document.getElementById(`${id}-appear`);
      if (prevAppear) parent?.removeChild(prevAppear);

      const showAnim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      showAnim.setAttribute("id", `${id}-appear`);
      const originColor = parent?.getAttribute("fill");
      showAnim.setAttribute("attributeName", "fill");
      showAnim.setAttribute("dur", "1s");
      showAnim.setAttribute("to", originColor ?? "");
      showAnim.setAttribute("begin", `${id}-${action}.end + ${trigger}s`);
      showAnim.setAttribute("fill", "freeze");

      parent?.appendChild(showAnim);
    }
  }, [trigger, color, duration]);

  useEffect(() => {
    const id = "leftHead";

    const parent = document.getElementById(id);

    if (trigger) {
      const prev = document.getElementById(`${id}-${action}`);
      if (prev) parent?.removeChild(prev);

      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-${action}`);

      anim.setAttribute("attributeName", "fill");
      anim.setAttribute("dur", duration);
      anim.setAttribute("to", color);
      anim.setAttribute("fill", "freeze");

      parent?.appendChild(anim);
      anim.beginElement();

      const prevAppear = document.getElementById(`${id}-appear`);
      if (prevAppear) parent?.removeChild(prevAppear);

      const showAnim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      showAnim.setAttribute("id", `${id}-appear`);
      const originColor = parent?.getAttribute("fill");
      showAnim.setAttribute("attributeName", "fill");
      showAnim.setAttribute("dur", "1s");
      showAnim.setAttribute("to", originColor ?? "");
      showAnim.setAttribute("begin", `${id}-${action}.end + ${trigger}s`);
      showAnim.setAttribute("fill", "freeze");

      parent?.appendChild(showAnim);
    }
  }, [trigger, color, duration]);

  return { start, stop, disappear };
};

export default useAlienColor;
