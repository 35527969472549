import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "./PushAudioRecorder.module.scss";
import { AudioOutlined, LoadingOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { NotifyContext } from "App";

const PushAudioRecorder: FC<{ onStart: Function; isPlaying: boolean; loading: boolean; setBlob: Function; disabled: boolean }> = ({
  onStart,
  isPlaying,
  setBlob,
  loading,
  disabled,
}) => {
  const [isRecording, setIsRecording] = useState(false);

  const timeoutRef = useRef<any>(null);
  const cancelRef = useRef<boolean>(false);

  const mediaStream = useRef<MediaStream>();
  const mediaRecorder = useRef<MediaRecorder>();
  const chunks = useRef<Blob[]>([]);
  const contextRef = useRef<AudioContext>();

  const notifyApi = useContext(NotifyContext);

  const startRecording = async () => {
    try {
      if ("audioSession" in navigator) {
        // @ts-ignore
        navigator.audioSession.type = "play-and-record";
      }
    } catch (e) {
      console.error(e);
    }

    if (!contextRef.current) {
      contextRef.current = new AudioContext();
    }
    cancelRef.current = false;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false,
        },
      });
      if (cancelRef.current) return;

      console.log("START RECORDING !!!");
      setIsRecording(true);
      onStart();

      const audioStreamSource = contextRef.current.createMediaStreamSource(stream);
      const analyser = contextRef.current.createAnalyser();
      analyser.minDecibels = -70;
      audioStreamSource.connect(analyser);

      const bufferLength = analyser.frequencyBinCount;
      const domainData = new Uint8Array(bufferLength);

      let soundDetected = false;

      const detectSound = () => {
        if (soundDetected) {
          return;
        }

        analyser.getByteFrequencyData(domainData);

        for (let i = 0; i < bufferLength; i++) {
          if (domainData[i] > 0) {
            soundDetected = true;
          }
        }

        window.requestAnimationFrame(detectSound);
      };

      window.requestAnimationFrame(detectSound);

      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream, {});
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/mpeg" });
        if (recordedBlob && soundDetected) {
          setBlob(recordedBlob);
        }

        chunks.current = [];
      };
      mediaRecorder.current.start(1000);
    } catch (error) {
      setIsRecording(false);
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = useCallback(() => {
    setIsRecording(false);
    console.log("STOP RECORDING");

    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      mediaRecorder.current = undefined;
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
      mediaStream.current = undefined;
    }
  }, []);

  useEffect(() => {
    if (isRecording) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        stopRecording();
        notifyApi.error({ message: "Лимит времени в 60 сек" });
      }, 60000);
    } else {
      clearTimeout(timeoutRef.current);
    }
  }, [isRecording, stopRecording, notifyApi]);

  const onMouseDown = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const onMouseUp = useCallback(() => {
    cancelRef.current = true;
    stopRecording();
  }, [stopRecording]);

  useEffect(() => {
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("touchend", onMouseUp);
    return () => {
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("touchend", onMouseUp);
    };
  }, [onMouseUp]);

  return (
    <div
      onTouchStart={isMobile ? onMouseDown : undefined}
      //onTouchEnd={isMobile ? onMouseUp : undefined}
      onMouseDown={isMobile ? undefined : onMouseDown}
      // onMouseUp={isMobile ? undefined : onMouseUp}
      className={styles.pushAudioRecorder}
      data-recording={isRecording}
      data-playing={isPlaying}
    >
      {loading ? (
        <LoadingOutlined style={{ fontSize: 32, color: "white" }} />
      ) : (
        <AudioOutlined style={{ fontSize: 32, color: disabled ? "darkgray" : "white" }} />
      )}
    </div>
  );
};
export default PushAudioRecorder;
