import { useCallback, useEffect, useRef, useState } from "react";
import { random, uniqueId } from "lodash";
import useAlienWink from "Components/AnimatedAlien/useAlienWink";

const action = "speak";
const duration = "0.7s";
const count = "2";

const useAlienSpeak = () => {
  const [active, setActive] = useState(false);
  const [trigger, setTrigger] = useState<string>();

  const play = useCallback(() => setActive(true), []);
  const stop = useCallback(() => setActive(false), []);
  const speak = useCallback(() => setTrigger(uniqueId(action)), []);

  const intervalRef = useRef<any>();
  const winkTimerRef = useRef<any>();

  const { wink } = useAlienWink({ count: "1" });

  const playWink = useCallback(() => {
    wink();
    winkTimerRef.current = setTimeout(playWink, random(500, 4000));
  }, [wink]);

  useEffect(() => {
    if (active) {
      setTrigger(uniqueId(action));
      playWink();
      intervalRef.current = setInterval(() => setTrigger(uniqueId(action)), 700);
    } else {
      setTrigger(undefined);
      clearInterval(intervalRef.current);
      clearTimeout(winkTimerRef.current);
    }
  }, [active, playWink, wink]);

  useEffect(() => {
    const id = "alien_mouth";

    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    const prev = document.getElementById(`${id}-${action}`);
    if (prev) parent?.removeChild(prev);

    if (trigger && parent) {
      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-${action}`);

      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", duration);
      anim.setAttribute("repeatCount", count);
      anim.setAttribute(
        "values",
        [
          originPath,
          "M222.5,374.6c-14.3,0-28.3-1.3-40.2-4-4.8-1.1-5.7-3-1.9-4.4,3.6-1.4,10.5-1.6,15.5-.6,15.7,3.5,37.6,3.5,53.3,0,4.8-1.1,11.5-.8,15.5.6,3.6,1.4,2.9,3.4-1.9,4.4-12.2,2.6-26.5,4-40.3,4h0Z",
          originPath,
        ].join(";"),
      );

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger]);

  return { play, stop, speak };
};

export default useAlienSpeak;
