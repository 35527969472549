import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CustomText, StatusType, TaskType } from "App.types";
import { HistoryEditor, withHistory } from "slate-history";
import { BaseEditor, BaseElement, createEditor, Transforms } from "slate";
import { Button, Space, Tag } from "antd";
import API from "Api";
import { delay } from "lodash";
import cx from "classnames";
import { getCompletedSlate, withCustomLogic } from "App.helpers";
import { AudioOutlined, CloseOutlined, SoundFilled } from "@ant-design/icons";
import SentenceMaskedLeaf from "./SentenceLeaf";
import { useOnCheck } from "./Helpers/useOnCheck";
import { usePressEnter } from "./Helpers/usePressEnter";
import { SentenceTaskProps } from "./SentenceTask.type";
import { useOnComplete } from "./Helpers/useOnComplete";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import TaskPanel from "Components/TaskPanel";
import styles from "./SentenceTask.module.scss";
import useCompletedSlate from "./Helpers/useCompletedSlate";
import { useIsBeginner } from "Hooks/useIsBeginner";
import RecordButton from "Components/RecordButton";
import { ReactComponent as KeyboardSvg } from "Assets/keyboard.svg";
import { NotifyContext } from "App";
import usePrevious from "Hooks/usePrevious";
import useSocketSpeech from "Hooks/useSocketSpeech";
import { useSpeechCheckTrans } from "Hooks/useSpeechCheckTrans";

const initialValue = [{ children: [{ text: "" }] }];

export type CustomElement = { children: CustomText[] } & BaseElement;

declare module "slate" {
  export interface CustomTypes {
    Editor: ReactEditor & BaseEditor & HistoryEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

const SentenceTask: FC<SentenceTaskProps> = ({
  sentence,
  sentence: { id, text, tags, translate, alternatives, storyId, phrases },
  activeType,
  lesson,
  onTaskComplete,
  onNext,
  children,
  audio,
  showSuccessMessage,
  alignCenter = false,
  setTry,
  noTranslate,
  transcripts,
  showGrammar,
  setDictOpened,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);
  const [hints, setHints] = useState<string[]>([]);
  const [isVoiceMode, setVoiceMode] = useState<boolean>(activeType === TaskType.VoiceTranslate);

  const editor = useMemo(() => withReact(withHistory(withCustomLogic(createEditor()))), []);

  const setInitialState = useCallback(
    (withFocus: boolean = true) => {
      editor.children.forEach(() => {
        Transforms.delete(editor, { at: [0] });
      });

      editor.children = [];

      Transforms.insertNodes(editor, initialValue, { select: withFocus });
      if (withFocus) {
        try {
          ReactEditor.focus(editor);
        } catch (e) {}
      }
    },
    [editor],
  );

  const setCompletedState = useCompletedSlate({ tags, text, editor });

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    if (!isVoiceMode) setInitialState(true);
    const hintTags = tags.filter((t, idx) => t.isHint && !phrases.some((ph) => ph.positionsIdx.includes(idx))).map((t) => t.lemma);
    const hintPhrases = phrases.filter((ph) => ph.positionsIdx.some((idx) => tags[idx].isHint)).map((ph) => ph.text);

    setHints([...hintTags, ...hintPhrases]);
  }, [sentence.id, setInitialState, setCompletedState, tags, phrases, isVoiceMode]);

  const { results: speechResults, start, stop, isSpeaking } = useSocketSpeech({ setStatus, text, interim_results: true });

  const { completedTagIdx, setCompletedTagIdx, activeTags, reset } = useSpeechCheckTrans({
    sentence,
    speechResults,
  });

  useEffect(() => {
    if (completedTagIdx >= 0 && completedTagIdx >= activeTags.length - 1) {
      stop();
      setStatus(StatusType.Completed);
      onTaskComplete();
      // setActiveSpeechIdx(-1);
    }
  }, [completedTagIdx, activeTags, onTaskComplete, stop]);

  const onRecordClick = () => {
    if (status === StatusType.IsRecording) {
      stop();
      reset();
    } else {
      setCompletedTagIdx(-1);
      setStatus(StatusType.Loading);
      start();
    }
  };

  // audio transcript
  useAudioTranscript({ setActiveLeaf, sentence, audio, transcripts });

  const onComplete = useOnComplete({ text, tags, setStatus, editor, onTaskComplete, showSuccessMessage });

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        underlined={props.leaf.audioIdx === (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const onCheck = useOnCheck({
    editor,
    activeType,
    text,
    tags,
    phrases,
    id,
    lesson,
    alternatives,
    onComplete,
    setStatus,
  });

  const onVoiceHint = useCallback(() => {
    setCompletedTagIdx((prev) => prev + 1);
  }, [setCompletedTagIdx]);

  const onHintAnswer = async () => {
    const text = editor.children[0]?.children?.map((el: CustomText) => el.text).join("");
    API.event.save({ text, type: "hint", task: activeType, lesson: { id: lesson.id }, sentence });

    const status = await onCheck(text, true);
    const wrongIdx = editor.children[0]?.children.findIndex((child: CustomText) => child.status === "wrong");
    if (wrongIdx >= 0) {
      Transforms.insertText(editor, "", { at: [0, wrongIdx] });
      await onCheck("", true);
    }

    if (status) {
      const hasError = editor.children[0]?.children.every((el: CustomText, idx: number) => {
        if (["missed", "lemma", "typo"].includes(el.status || "")) {
          setTry(true);
          Transforms.setNodes(editor, { status: "success" }, { at: [0, idx] });
          Transforms.insertText(editor, el.word || el.answer || "", { at: [0, idx] });
          setActiveLeaf(el.idx || null);
          delay(() => setActiveLeaf(null), 2000);
          return false;
        }

        if (el.status === "order") {
          editor.children.forEach(() => {
            Transforms.delete(editor, { at: [0] });
          });

          editor.children = [];
          Transforms.insertNodes(editor, [{ children: getCompletedSlate(tags, text) }]);
          return false;
        }
        return true;
      });

      if (hasError) {
        setTry();
      }
    }
  };

  usePressEnter({ isCompleted: status === StatusType.Completed, onNext, onCheck });

  const isBeginner = useIsBeginner();

  const onReset = () => {
    setInitialState(status === StatusType.Editing);
    ReactEditor.focus(editor);
  };

  const notify = useContext(NotifyContext);

  const prevMode = usePrevious(isVoiceMode);

  useEffect(() => {
    if (!isVoiceMode) setInitialState();
    if (prevMode !== isVoiceMode) notify.info({ message: isVoiceMode ? "Голосовой перевод" : "Письменный перевод" });
  }, [isVoiceMode, notify, prevMode, setInitialState]);

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            {isVoiceMode ? (
              <div className={styles.textArea}>
                <div className={styles.voiceTags}>
                  {activeTags.map((tag, idx) => (
                    <span
                      className={cx(styles.voiceTag, {
                        [styles.voiceTag__hint]: tag.isHint && status !== StatusType.IsRecording,
                        [styles.voiceTag__active]: status !== StatusType.Completed,
                        [styles.voiceTag__completed]: idx <= completedTagIdx || status === StatusType.Completed,
                        [styles.voiceTag__merged]: tag.merged,
                      })}
                      key={`${idx}${tag.word}`}
                    >
                      {tag.word}
                    </span>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <form spellCheck="false">
                  <Slate editor={editor} initialValue={initialValue}>
                    <Editable
                      className={styles.textArea}
                      readOnly={status === "completed"}
                      onKeyDown={() => setStatus(StatusType.Editing)}
                      renderLeaf={renderLeaf}
                      //renderElement={(props) => <StoryElement isActive={activeSent === props.element.id} play={play} {...props} />}
                    />
                  </Slate>
                </form>
                {status !== "completed" && (
                  <Button
                    size={"small"}
                    type={"text"}
                    className={styles.btn__clear}
                    icon={<CloseOutlined style={{ fontSize: 12 }} />}
                    onClick={onReset}
                  />
                )}
              </>
            )}
          </div>
          {(!noTranslate || status === StatusType.Completed) && (
            <div className={styles.translate}>
              {[TaskType.Translate, TaskType.Convert, TaskType.VoiceTranslate].includes(activeType) && translate}
            </div>
          )}
        </div>

        <div className={styles.bottom}>
          {status !== StatusType.Completed && (
            <Space>
              {hints.map((hint) => (
                <Tag style={{ fontSize: 16 }} bordered={false} key={hint}>
                  {hint}
                </Tag>
              ))}
            </Space>
          )}

          <Button
            icon={<SoundFilled />}
            onClick={() => {
              audio?.play();
              ReactEditor.focus(editor);
            }}
            style={{ visibility: status === "completed" || activeType === TaskType.Dictate ? "visible" : "hidden" }}
          />
        </div>
      </div>

      <TaskPanel
        onCheck={onCheck}
        lessonId={lesson.id}
        task={activeType}
        sentId={id}
        storyId={storyId}
        isCompleted={status === StatusType.Completed}
        setDictOpened={setDictOpened}
        onNext={onNext}
        audio={audio}
        onHint={isVoiceMode ? onVoiceHint : onHintAnswer}
        showGrammar={showGrammar}
      >
        {isVoiceMode ? (
          <Space>
            <RecordButton isSpeaking={isSpeaking} isRecording={status === StatusType.IsRecording} onClick={onRecordClick} />
            <Button type={"link"} icon={<KeyboardSvg width={40} height={38} />} onClick={() => setVoiceMode(false)} />
          </Space>
        ) : (
          <Space>
            <Button type={"primary"} shape={"round"} onClick={() => onCheck()}>
              {isBeginner ? "проверить" : "check"}
            </Button>
            <Button icon={<AudioOutlined />} onClick={() => setVoiceMode(true)} />
          </Space>
        )}
      </TaskPanel>
    </div>
  );
};

export default SentenceTask;
