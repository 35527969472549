import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AlternativeType, SentenceType, SpeechResultType, TagType } from "App.types";
import { isEqualEnglishWords } from "App.helpers";
import { flatten, takeRight } from "lodash";
import { NotifyContext } from "App";
import { SkipTags } from "App.constants";
import usePrevious from "Hooks/usePrevious";
import { useSpeechWords } from "Hooks/useSpeechWords";

type Props = {
  enabled?: boolean;
  speechResults: SpeechResultType[] | undefined;
  sentence: SentenceType | undefined;
};

export const useSpeechCheckTrans = ({ enabled = true, speechResults, sentence }: Props) => {
  const [wrongCount, setWrongCount] = useState(0);
  const [wordIdx, setWordIdx] = useState<number>(0);
  const [completedTagIdx, setCompletedTagIdx] = useState<number>(-1);
  const [activeTags, setActiveTags] = useState<Array<TagType & { idx: number; merged: boolean }>>([]);
  const [alternatives, setAlternatives] = useState<AlternativeType[]>([]);

  const WRONG_TRIES = 2 + ((sentence?.tags.length ?? 0) === 1 ? 3 : 0);

  const wordHomophones = useSpeechWords(sentence);

  const reset = useCallback(() => {
    setCompletedTagIdx(-1);
    setWrongCount(0);
    setActiveTags(
      sentence?.tags.map((t, idx) => ({
        ...t,
        idx,
        merged: !!idx && sentence?.text.includes(`${sentence?.tags[idx - 1].word}${t.word}`),
      })) ?? [],
    );
  }, [sentence]);

  useEffect(() => {
    setWrongCount(0);
  }, [completedTagIdx]);

  useEffect(() => reset(), [reset]);

  useEffect(() => {
    setWrongCount(0);
    setCompletedTagIdx(-1);
    setWordIdx(0);
  }, [sentence?.tags, setCompletedTagIdx]);

  const notifyApi = useContext(NotifyContext);

  useEffect(() => {
    if (SkipTags.includes(activeTags[completedTagIdx + 1]?.word)) {
      setCompletedTagIdx(completedTagIdx + 1);
    }
  }, [activeTags, completedTagIdx, setCompletedTagIdx]);

  useEffect(() => {
    setWordIdx(0);
  }, [speechResults]);

  const lastTagIdx = usePrevious(completedTagIdx);

  useEffect(() => {
    if (lastTagIdx === completedTagIdx && wrongCount > WRONG_TRIES && enabled) {
      const activeTag = activeTags[completedTagIdx + 1];

      if (activeTag) {
        setCompletedTagIdx((prev) => prev + 1);
        notifyApi?.warning({
          message: (
            <div style={{ fontSize: 18 }}>
              подсказка: <b>{activeTags[completedTagIdx + 1].word}</b>
            </div>
          ),
        });
      }
      setWrongCount(0);
    }
  }, [WRONG_TRIES, activeTags, completedTagIdx, enabled, lastTagIdx, notifyApi, setCompletedTagIdx, wrongCount]);

  useEffect(() => {
    if (sentence?.alternatives.length) setAlternatives(sentence?.alternatives);
  }, [sentence?.alternatives]);

  const filteredAlternatives = useMemo(() => {
    const completedTags = activeTags.slice(0, completedTagIdx + 1);

    return completedTags.length
      ? alternatives.filter((alt) => completedTags.every((t, idx) => isEqualEnglishWords(alt.tags?.[idx]?.word, t.word)))
      : alternatives;
  }, [alternatives, activeTags, completedTagIdx]);

  useEffect(() => {
    if (speechResults?.length && speechResults.some((r) => r.isFinal) && enabled) setWrongCount((prev) => prev + 1);
  }, [speechResults, enabled]);

  const lastResults = usePrevious(speechResults);

  useEffect(() => {
    if (
      (lastResults !== speechResults && wordIdx) ||
      completedTagIdx >= activeTags.length - 1 ||
      !speechResults?.length ||
      !enabled ||
      !speechResults.some((a) => a.words[wordIdx])
    )
      return;

    const alternative = sentence?.alternatives.find(
      (alt) =>
        !alt.wrong &&
        speechResults.some(
          (result) => isEqualEnglishWords(alt.text, result.transcript) || isEqualEnglishWords(result.transcript, alt.text),
        ),
    );

    if (alternative?.tags) {
      setActiveTags(
        alternative.tags.map((t, idx) => ({
          ...t,
          idx,
          merged: !!idx && alternative?.text.includes(`${alternative?.tags[idx - 1].word}${t.word}`),
        })) ?? [],
      );
      return setCompletedTagIdx(Infinity);
    }

    if (speechResults.some((result) => isEqualEnglishWords(result.transcript, sentence?.text))) {
      setCompletedTagIdx(Infinity);
    } else {
      speechResults.some((speechResult) => {
        const speechWord = speechResult.words[wordIdx]?.word;
        const activeTag = activeTags[completedTagIdx + 1];
        activeTag.word = activeTag.word.replaceAll("’", "'");
        if (!speechWord || !activeTag?.word) return false;

        console.log(
          "compare:",
          activeTag?.word,
          speechWord,
          isEqualEnglishWords(activeTag?.word, speechWord) || isEqualEnglishWords(speechWord, activeTag?.word),
        );

        // check what's => what is, ...etc
        const contractions = speechWord.replaceAll("’", "'").split("'");
        if (
          contractions.length === 2 &&
          isEqualEnglishWords(activeTag.word, contractions[0]) &&
          isEqualEnglishWords(`'${contractions[1]}`, `${activeTags[completedTagIdx + 2]?.word}`)
        ) {
          setCompletedTagIdx((prev) => prev + 2);
          return true;
        }
        if (
          contractions.length === 2 &&
          isEqualEnglishWords(`'${contractions[0]}`, `${activeTags[completedTagIdx]?.word}`) &&
          isEqualEnglishWords(`'${contractions[1]}`, `${activeTags[completedTagIdx + 1]?.word}`)
        ) {
          setCompletedTagIdx((prev) => prev + 1);
          return true;
        }

        // words with "-", example: well-being
        if (speechWord.includes("-")) {
          const fullWord = `${activeTags[completedTagIdx + 1].word}${activeTags[completedTagIdx + 2]?.word}${activeTags[completedTagIdx + 3]
            ?.word}`;

          if (isEqualEnglishWords(speechWord, fullWord) && isEqualEnglishWords(fullWord, speechWord)) {
            setCompletedTagIdx((prev) => prev + 3);
            return true;
          }
        }

        // check don't, can't, etc...
        if (
          isEqualEnglishWords(`${activeTag.word}${activeTags[completedTagIdx + 2]?.word}`, speechWord) ||
          isEqualEnglishWords(speechWord, `${activeTag.word}${activeTags[completedTagIdx + 2]?.word}`)
        ) {
          setCompletedTagIdx((prev) => prev + 2);
          return true;
        }

        // check homophones
        if (
          wordHomophones?.[activeTag.word.toLowerCase()]?.some(
            (word) => isEqualEnglishWords(word, speechWord) || isEqualEnglishWords(speechWord, word),
          )
        ) {
          setCompletedTagIdx((prev) => prev + 1);
          return true;
        }

        if (isEqualEnglishWords(activeTag.word, speechWord) || isEqualEnglishWords(speechWord, activeTag?.word)) {
          setCompletedTagIdx((prev) => prev + 1);
          return true;
        }

        const phrases = sentence?.phrases.filter((ph) => ph.positionsIdx[0] === activeTag?.idx);

        const phrase = phrases?.find((ph) => ph.synonyms.some((s) => isEqualEnglishWords(s.split(" ")[0], speechWord)));
        const synPhrase = phrase?.synonyms.find((s) => isEqualEnglishWords(s.split(" ")[0], speechWord));

        if (synPhrase && phrase) {
          const words = synPhrase.split(" ");
          const synTags = words.map((w) => ({ word: w }));

          setActiveTags((prev) => {
            const newAr: any[] = prev.filter((el, idx) => idx < completedTagIdx + 1 || idx >= completedTagIdx + phrase.positionsIdx.length);
            // phrase.positionsIdx.forEach(() => delete newAr[completedTagIdx + 1 + wordIdx]);
            newAr[completedTagIdx + 1] = synTags;
            return flatten(newAr);
          });

          setCompletedTagIdx((prev) => prev + 1);
          return true;
        }

        const synonym = activeTag?.synonyms?.find(
          (s) => isEqualEnglishWords(s.split(" ")[0], speechWord) || wordHomophones[s]?.some((hm) => isEqualEnglishWords(hm, speechWord)),
        );
        if (synonym) {
          const synTags = synonym.split(" ").map((w) => ({ word: w, synonyms: [] }));
          // проверить если другие фразы в синонимах которые начинаются с того же слова
          const otherPhrases = activeTag?.synonyms.filter(
            (syn) => syn.split(" ").length > 1 && syn !== synonym && isEqualEnglishWords(syn.split(" ")[0], speechWord),
          );
          if (otherPhrases?.length && synTags.length > 1) {
            // @ts-ignore
            synTags[1].synonyms = otherPhrases.map((ph) => takeRight(ph.split(" "), ph.split(" ").length - 1).join(" "));
          }

          setActiveTags((prev) => {
            const newAr: any[] = [...prev];
            newAr[completedTagIdx + 1] = synTags;

            return flatten(newAr);
          });

          setCompletedTagIdx((prev) => prev + 1);
          return true;
        }

        if (synonym) {
          const synTags = synonym.split(" ").map((w) => ({ word: w }));
          setActiveTags((prev) => {
            const newAr: any[] = [...prev];
            newAr[completedTagIdx + 1] = synTags;
            return flatten(newAr);
          });

          setCompletedTagIdx((prev) => prev + 1);
          return true;
        }

        const alt = filteredAlternatives.find(
          (alt) =>
            isEqualEnglishWords(alt.tags[completedTagIdx + 1].word, speechWord) ||
            wordHomophones?.[alt.tags[completedTagIdx + 1].word]?.some((hm) => isEqualEnglishWords(hm, speechWord)),
        );
        if (alt) {
          setActiveTags(
            alt.tags.map((t, idx) => ({
              ...t,
              idx,
              merged: !!idx && alt.text.includes(`${alt?.tags[idx - 1].word}${t.word}`),
            })) ?? [],
          );
          setCompletedTagIdx((prev) => prev + 1);
          return true;
        }

        // check don't etc...
        const alt2Tags = filteredAlternatives.find((alt) =>
          isEqualEnglishWords(`${alt.tags[completedTagIdx + 1].word}${alt.tags[completedTagIdx + 2]?.word}`, speechWord),
        );
        if (alt2Tags) {
          setActiveTags(
            alt2Tags.tags.map((t, idx) => ({
              ...t,
              idx,
              merged: !!idx && alt2Tags.text.includes(`${alt2Tags?.tags[idx - 1].word}${t.word}`),
            })) ?? [],
          );
          setCompletedTagIdx((prev) => prev + 2);
          return true;
        }

        return false;
      });

      setWordIdx((prev) => prev + 1);
    }
  }, [
    wordHomophones,
    filteredAlternatives,
    sentence,
    activeTags,
    speechResults,
    enabled,
    completedTagIdx,
    setCompletedTagIdx,
    setActiveTags,
    wordIdx,
    lastResults,
  ]);

  return { reset, completedTagIdx, setCompletedTagIdx, activeTags };
};
