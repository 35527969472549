import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import React, { FC, KeyboardEventHandler, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CustomText, StatusType, TaskType } from "App.types";
import { createEditor, Transforms } from "slate";
import { successMessage } from "App.constants";
import { Button } from "antd";
import API from "Api";
import { delay } from "lodash";
import { getInitialMaskedSlate, isEqualEnglishWords, isEqualText, withCustomMaskedLogic } from "App.helpers";
import { CloseOutlined, SoundFilled } from "@ant-design/icons";
import SentenceMaskedLeaf from "./SentenceLeaf";
import styles from "./SentenceTask.module.scss";
import { SentenceTaskProps } from "./SentenceTask.type";
import { usePressEnter } from "./Helpers/usePressEnter";
import { useAudioTranscript } from "./Helpers/useAudioTranscript";
import cx from "classnames";
import useMergedTags from "Components/useMergedTags";
import TaskPanel from "Components/TaskPanel";
import useCompletedSlate from "./Helpers/useCompletedSlate";
import { NotifyContext } from "App";

const initialValue = [{ children: [{ text: "" }] }];

const SentenceTask: FC<SentenceTaskProps> = ({
  audio,
  sentence,
  sentence: { id, text, translate, alternatives, storyId },
  lesson,
  onTaskComplete,
  onNext,
  children,
  setDictOpened,
  showGrammar,
  setTry,
  alignCenter,
  transcripts,
  noTranslate,
  showSuccessMessage,
}) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Editing);
  const [activeLeaf, setActiveLeaf] = useState<number | null>(null);

  const editor = useMemo(() => withReact(withCustomMaskedLogic(createEditor())), []);
  const notifyApi = useContext(NotifyContext);

  const [{ tags: mergedTags }] = useMergedTags(sentence);

  const onReset = useCallback(() => {
    editor.children.forEach(() => {
      Transforms.delete(editor, { at: [0] });
    });

    editor.children = [];

    Transforms.insertNodes(editor, [{ children: getInitialMaskedSlate(mergedTags, text) }]);
    const maskedIdx = editor.children[0].children.findIndex((child: CustomText) => child.isMasked);
    Transforms.setSelection(editor, { anchor: { path: [0, maskedIdx], offset: 0 }, focus: { path: [0, maskedIdx], offset: 0 } });
    try {
      ReactEditor.focus(editor);
    } catch (e) {
      console.error(e);
    }
  }, [mergedTags, editor, text]);

  const setCompletedState = useCompletedSlate({ tags: mergedTags, text, editor });

  // initial
  useEffect(() => {
    setActiveLeaf(null);
    setStatus(StatusType.Editing);
    onReset();
  }, [sentence.id, onReset, setCompletedState]);

  const onComplete = useCallback(
    (showMessage = true) => {
      setStatus(StatusType.Completed);

      if (showMessage && showSuccessMessage) {
        notifyApi.destroy();
        notifyApi.success(successMessage);
      }

      onTaskComplete();
    },
    [onTaskComplete, notifyApi, showSuccessMessage],
  );

  const renderLeaf = useCallback(
    (props: any) => (
      <SentenceMaskedLeaf
        showMasked
        hideMasked={status === StatusType.Completed}
        underlined={props.leaf.audioIdx <= (activeLeaf ?? -1)}
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf],
  );

  const maskedCheck = useCallback(async () => {
    const maskedNodes: CustomText[] = editor.children[0].children.filter((el: CustomText) => el.isMasked);

    if (maskedNodes.every((children) => isEqualText(children.text, children.answer))) {
      return onComplete();
    }

    const spellResults = await API.speller.get(maskedNodes);
    const lemmas = (await API.lemma.get(maskedNodes.map((el) => el.text).join(","))).filter((el) => el.word !== ",");

    let hasErrors = false;
    maskedNodes.forEach((child: CustomText, idx: number) => {
      let wordStatus = "";
      if (
        child.synonyms.some((syn) => isEqualEnglishWords(syn, child.text)) ||
        isEqualEnglishWords(child.text, child.answer) ||
        alternatives.some((alt) => isEqualEnglishWords(alt.text, child.text))
      ) {
      } else if (child.isMasked && child.text === "") {
        wordStatus = "empty";
      } else if (spellResults[idx][0]?.s.includes(child.answer) || spellResults[idx][0]?.s.includes(child.lemma)) {
        notifyApi.error({ message: <b>{child.text}</b>, description: "слово содержит опечатки" });
        wordStatus = "typo";
      } else if (isEqualText(lemmas[idx]?.lemma, child.lemma)) {
        notifyApi.warning({ message: <b>{child.text}</b>, description: "неверная форма слова" });
        wordStatus = "lemma";
      } else {
        wordStatus = "wrong";
      }
      if (wordStatus) {
        setStatus(StatusType.Error);
        hasErrors = true;
      }

      Transforms.setNodes(editor, { ...child, status: wordStatus }, { at: [0, child.idx] });
    });

    if (!hasErrors) {
      onComplete();
    } else {
      setTry();
    }
  }, [editor, notifyApi, onComplete, setTry, alternatives]);

  const onMaskedHint = () => {
    API.event.save({ text, type: "hint", task: TaskType.Masked, lesson: { id: lesson.id }, sentence });

    editor.children[0]?.children.every((el: CustomText, idx: number) => {
      if (el.isMasked && !isEqualText(el.text, el.answer)) {
        setStatus(StatusType.Error);
        Transforms.insertText(editor, el.answer || "", { at: [0, idx] });
        Transforms.setNodes(editor, { status: "hint" }, { at: [0, idx] });
        // setActiveLeaf(el.idx || null);
        setTry(true);
        delay(() => Transforms.setNodes(editor, { status: "" }, { at: [0, idx] }), 2000);
        return false;
      }

      return true;
    });
  };

  const onEnter = useCallback(() => {
    return new Promise((resolve, reject) => {
      const curIdx = editor.selection?.focus.path[1] ?? 0;

      const children = editor.children[0].children;
      const nextIdx = children.findIndex((el: any) => el.idx > curIdx && el.isMasked);
      if (nextIdx > -1) {
        const offset = children[nextIdx].text.length;
        Transforms.setSelection(editor, { focus: { offset, path: [0, nextIdx] }, anchor: { offset, path: [0, nextIdx] } });
      } else {
        maskedCheck();
      }
    });
  }, [maskedCheck, editor]);

  usePressEnter({ isCompleted: status === StatusType.Completed, onNext, onCheck: onEnter });

  useAudioTranscript({ audio, sentence, setActiveLeaf, transcripts });

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = () => {
    setStatus(StatusType.Editing);
  };

  return (
    <div className={styles.sentenceTask}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>
        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <form spellCheck="false">
              <Slate editor={editor} initialValue={initialValue}>
                <Editable
                  className={styles.textArea}
                  readOnly={status === "completed"}
                  onKeyDown={onKeyDown}
                  // onKeyDown={onKeyDownMasked}
                  renderLeaf={renderLeaf}
                />
              </Slate>
            </form>
            {status !== "completed" && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CloseOutlined style={{ fontSize: 12 }} />}
                onClick={onReset}
              />
            )}
          </div>
          {(!noTranslate || status === StatusType.Completed) && <div className={styles.translate}>{translate}</div>}
        </div>

        <div className={styles.bottom}>
          <Button
            icon={<SoundFilled />}
            onClick={() => audio?.play()}
            style={{ visibility: status === "completed" ? "visible" : "hidden" }}
          />
        </div>
      </div>

      <TaskPanel
        lessonId={lesson.id}
        task={TaskType.Masked}
        sentId={id}
        storyId={storyId}
        onCheck={maskedCheck}
        onNext={onNext}
        isCompleted={status === StatusType.Completed}
        onHint={onMaskedHint}
        setDictOpened={setDictOpened}
        audio={audio}
        showGrammar={showGrammar}
      ></TaskPanel>
    </div>
  );
};

export default SentenceTask;
