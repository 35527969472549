import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import styles from "./VideoPlayer.module.scss";
import { MediaType } from "App.types";
import { STATIC_URL } from "App.constants";
import { encodeS3URI } from "App.helpers";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import { PlaySquareOutlined } from "@ant-design/icons";
import { OnProgressProps } from "react-player/base";
import { max } from "lodash";

type Props = {
  medias: Partial<MediaType>[];
  onFinish?: () => any;
  autoPlay?: boolean;
  showCaptions: boolean;
  largeCaptionSize?: boolean;
  className?: string;
  withFullscreen?: boolean;
  defaultHeight?: number;
};

const VideoPlayer: FC<Props & PropsWithChildren> = ({
  withFullscreen,
  className = "",
  largeCaptionSize,
  showCaptions,
  onFinish,
  medias,
  autoPlay = false,
  defaultHeight = 360,
}) => {
  const [activeIdx, setActiveIdx] = useState<number>(0);
  const [minHeight, setMinHeight] = useState<number>(0);
  const [isPlaying, setPlaying] = useState(autoPlay);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    autoPlay && setPlaying(true);
  }, [autoPlay]);

  // const queries = useSuspenseQueries({
  //   queries: medias.slice(1, medias.length).map((m) => ({
  //     staleTime: Infinity,
  //     queryKey: ["media", m.url],
  //     queryFn: () =>
  //       axios.get(`${STATIC_URL}/media/${encodeS3URI(m.url)}`, { headers: { "Cache-Control": "no-cache" }, responseType: "blob" }),
  //   })),
  //   combine: (data) => data.map((resp) => URL.createObjectURL(resp.data.data)),
  // });

  // const urls = useMemo(() => [`${STATIC_URL}/media/${encodeS3URI(medias[0].url)}`, ...queries], [medias, queries]);

  const urls = useMemo(() => medias.map((m) => `${STATIC_URL}/media/${encodeS3URI(m.url)}`), [medias]);

  const activeClip = medias[activeIdx];

  const [duration, setDuration] = useState<number>();
  const [fullScreen, setFullScreen] = useState(false);
  // const [end, setEnd] = useState<number | undefined>();

  const playerRef = useRef<ReactPlayer>(null);

  const { start = 0, layout, end } = activeClip;

  useEffect(() => {
    if (duration) {
      playerRef.current?.seekTo(start);
      // playerRef.current?.forceUpdate();
      // setEnd(activeClip.end);
    }
  }, [activeClip.end, start, duration]);

  const text = showCaptions ? activeClip.title : "";

  useEffect(() => {
    const player = playerRef.current?.getInternalPlayer();
    if (player?.textTracks?.length) {
      [...player.textTracks].forEach((track: any) => (track.mode = "disabled"));
    }
    if (player && duration && text?.trim()) {
      let text1 = player.addTextTrack("captions", "English", "en");
      text1.mode = "showing";
      text1.addCue(new VTTCue(0, 99, text));
    }
  }, [duration, text]);

  const containerRef = useRef<HTMLDivElement>(null);

  const isVertical = useMemo(() => {
    const player = playerRef.current?.getInternalPlayer();
    return duration && (layout === "vertical" || player?.videoWidth < player?.videoHeight);
  }, [layout, duration]);

  useEffect(() => {
    if (!fullScreen && isPlaying && isVertical && withFullscreen && isMobile && containerRef.current?.requestFullscreen) {
      setPlaying(false);
    }
  }, [fullScreen, isPlaying, isVertical, withFullscreen]);

  const onClick = () => {
    if (finished) {
      setFinished(false);
    }
    setActiveIdx(0);
    setPlaying((prev) => !prev);

    if (fullScreen) {
      setFullScreen(false);
      document.exitFullscreen && document.exitFullscreen();
    } else {
      if (isVertical && withFullscreen && isMobile && containerRef.current?.requestFullscreen)
        containerRef.current.requestFullscreen().then(() => {
          setFullScreen(true);
          setPlaying(true);
        });
    }
  };

  const onVideoEnd = useCallback(() => {
    setActiveIdx((prev = 0) => {
      if (prev === medias.length - 1) {
        setPlaying(false);
        onFinish?.();
        setFinished(true);
        return prev;
      }
      return prev + 1;
    });
  }, [medias.length, onFinish]);

  const onProgress = useCallback(
    (s: OnProgressProps) => {
      if (end && s.playedSeconds >= end) {
        //setEnd(undefined);
        onVideoEnd();
      }

      if (isMobile && !fullScreen) {
        const curHeight = containerRef.current?.clientHeight ?? 0;
        setMinHeight((prev) => max([prev, curHeight]) ?? 0);
      }
    },
    [end, onVideoEnd, fullScreen],
  );

  return (
    <div
      className={cx(styles.videoPlayer, className, {
        [styles.largeCaptions]: largeCaptionSize,
        [styles.videoPlayer__withCaptions]: showCaptions,
        [styles.videoPlayer__black]: isVertical,
      })}
    >
      <div
        ref={containerRef}
        onClick={onClick}
        style={{
          //background: duration && !isMobile && withBackground ? "black" : undefined,
          height: isMobile ? "" : "100%",
          minHeight: isMobile ? minHeight : undefined,
          position: "relative",
        }}
      >
        <ReactPlayer
          progressInterval={100}
          onProgress={onProgress}
          playsinline={!(isVertical && withFullscreen && isMobile)}
          ref={playerRef}
          onDuration={setDuration}
          onError={(e) => {
            console.log("error", e);
            onVideoEnd();
          }}
          onEnded={() => {
            // setEnd(undefined);
            onVideoEnd();
            if (fullScreen && document.exitFullscreen) {
              try {
                document.exitFullscreen().then(() => setFullScreen(false));
              } catch (e) {}
            }
          }}
          playing={isPlaying}
          // onReady={() => playerRef?.current?.seekTo(0)}
          width={isMobile && !isVertical ? "100%" : ""}
          height={isMobile ? (fullScreen || (isVertical && !withFullscreen) ? "100%" : isVertical ? 240 : "") : defaultHeight}
          controls={false}
          url={urls[activeIdx]}
        />
        {!isPlaying && <PlaySquareOutlined className={styles.btnPlay} />}
      </div>

      {urls.map((url) => (
        <div key={url} style={{ display: "none" }}>
          <ReactPlayer playing={false} url={url} />
        </div>
      ))}
    </div>
  );
};

export default VideoPlayer;
