import { useCallback, useEffect, useRef, useState } from "react";
import { random, uniqueId } from "lodash";

const downs = [
  "M405.5,235.4c0,98.7-105.4,231.3-183,231.3S39.5,334.3,39.5,235.4,121.4,56.7,222.5,56.7s183,80.1,183,178.7Z",
  "M219.9,56.7c-99.8,1.3-180.4,81-180.4,178.7s104.5,230.1,182.1,231c-8.1-95-49.7-156.7-71.2-277.5-13.2-72.5,27.2-111.5,69.5-132.3h0Z",
  "M330.6,303.5c26.8-23.6,35-54.7,18.8-69-16.3-14.4-51.4-7-78.2,16.5-26.8,23.6-35.1,54.7-18.8,69.1,16.4,14.4,51.4,7,78.2-16.6h0Z",
  "M112.7,303.5c26.8,23.6,61.7,31.1,78.2,16.5,16.4-14.5,8.1-45.4-18.8-69s-61.7-31.1-78.2-16.5c-16.4,14.4-8.1,45.4,18.8,69Z",
  "M222.5,379.2c-8.3,0-16.4-2.6-23.3-7.8-2.8-2.2-3.3-6-1.1-8.6,2.1-2.8,6.1-3.2,9-1.1,9.1,6.9,21.8,6.9,30.9,0,2.8-2.2,6.7-1.5,9,1.1,2.1,2.8,1.7,6.7-1.1,8.6-7.1,5.2-15.4,7.8-23.4,7.8h0Z",
  "M93.9,234.4c-16.4,14.4-8.1,45.4,18.8,69,15.7,13.8,34,22,49.9,23.6-1.7-.4-28.3-5.9-35.7-41.9-7.6-36.9,45.1-34.2,45.1-34.2-26.6-23.5-61.4-31-78.1-16.5h0Z",
  "M349.1,234.4c16.4,14.4,8.1,45.4-18.8,69-15.7,13.8-34,22-49.9,23.6,1.7-.4,28.3-5.9,35.7-41.9,7.6-36.9-45.1-34.2-45.1-34.2,26.6-23.5,61.7-31,78.1-16.5h0Z",
];

const action = "wink";
const duration = "0.7s";

const useAlienWink = ({ count } = { count: "1" }) => {
  const [active, setActive] = useState(false);
  const [trigger, setTrigger] = useState<string>();

  const play = useCallback(() => setActive(true), []);
  const stop = useCallback(() => setActive(false), []);
  const wink = useCallback(() => setTrigger(uniqueId(action)), []);

  const timerRef = useRef<any>();

  const startTimer = useCallback(() => {
    wink();
    timerRef.current = setTimeout(startTimer, random(500, 10000));
  }, [wink]);

  useEffect(() => {
    if (active) {
      startTimer();
    } else {
      setTrigger(undefined);
      clearTimeout(timerRef.current);
    }
  }, [active, startTimer]);

  useEffect(() => {
    const id = "eRight";

    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    if (trigger && parent) {
      const prev = document.getElementById(`${id}-${action}`);
      if (prev) parent?.removeChild(prev);
      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-${action}`);

      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", duration);
      anim.setAttribute("repeatCount", count);
      anim.setAttribute("values", [originPath, downs[2], originPath].join(";"));

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger, count]);

  useEffect(() => {
    const id = "eLeft";

    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    if (trigger && parent) {
      const prev = document.getElementById(`${id}-${action}`);
      if (prev) parent?.removeChild(prev);
      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-${action}`);

      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", duration);
      anim.setAttribute("repeatCount", count);
      anim.setAttribute("values", [originPath, downs[3], originPath].join(";"));

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger, count]);

  useEffect(() => {
    const id = "pLeft";

    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    if (trigger && parent) {
      const prev = document.getElementById(`${id}-${action}`);
      if (prev) parent?.removeChild(prev);
      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-${action}`);

      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", duration);
      anim.setAttribute("repeatCount", count);
      anim.setAttribute("values", [originPath, downs[5], originPath].join(";"));

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger, count]);

  useEffect(() => {
    const id = "pRight";

    const parent = document.getElementById(id);

    const originPath = parent?.getAttribute("d");

    if (trigger && parent) {
      const prev = document.getElementById(`${id}-${action}`);
      if (prev) parent?.removeChild(prev);
      const anim = document.createElementNS("http://www.w3.org/2000/svg", "animate");
      anim.setAttribute("id", `${id}-${action}`);

      anim.setAttribute("attributeName", "d");
      anim.setAttribute("dur", duration);
      anim.setAttribute("repeatCount", count);

      anim.setAttribute("values", [originPath, downs[6], originPath].join(";"));

      parent?.appendChild(anim);
      anim.beginElement();
    }
  }, [trigger, count]);

  return { play, stop, wink };
};

export default useAlienWink;
