import React, { FC, PropsWithChildren, useEffect } from "react";
import { LessonType, SentenceType, SpeechResultType, TaskType } from "App.types";
import { Button, Popconfirm } from "antd";
import cx from "classnames";
import { BulbTwoTone, CheckOutlined } from "@ant-design/icons";
import styles from "../SpeechLessonTask.module.scss";
import { useSpeechCheckTrans } from "Hooks/useSpeechCheckTrans";

type Props = PropsWithChildren & {
  sentence: SentenceType;
  activeType: TaskType;
  lesson: LessonType;
  // setTries?: Dispatch<SetStateAction<number>>;
  audio?: HTMLAudioElement;
  alignCenter?: boolean;
  autoHeight?: boolean;
  active: boolean;
  speechResults: SpeechResultType[] | undefined;
  onComplete: () => void;
  isRecording: boolean;
};

const ScrollerTaskRow: FC<Props> = ({
  active,
  sentence,
  sentence: { text, translate },
  activeType,
  children,
  alignCenter = true,
  onComplete,
  isRecording,
  speechResults,
}) => {
  const { activeTags, completedTagIdx, setCompletedTagIdx, reset } = useSpeechCheckTrans({
    enabled: active,
    sentence,
    speechResults,
  });

  const isCompleted = completedTagIdx >= 0 && completedTagIdx + 1 >= activeTags.length - 1;

  useEffect(() => {
    if (isCompleted && active) {
      onComplete();
    }
  }, [onComplete, isCompleted, active]);

  useEffect(() => {
    if (active && isRecording) {
      reset();
    }
  }, [isRecording, active, reset]);

  return (
    <div className={cx(styles.speechLessonTask, { [styles.speechLessonTask__inActive]: !active && !isCompleted })}>
      <div className={cx(styles.content, { [styles.content_hasChildren]: !!children })}>
        <div className={styles.children}>{children}</div>

        <div className={cx(styles.slate, { [styles.slate_alignCenter]: alignCenter })}>
          <div className={styles.slate_wrapper}>
            <div className={styles.textArea}>
              <div className={styles.voiceTags}>
                {activeTags.map((tag, idx) => (
                  <span
                    className={cx(styles.voiceTag, {
                      [styles.voiceTag__hint]: tag.isHint && !isRecording,
                      [styles.voiceTag__active]:
                        (activeType !== TaskType.Read && !isCompleted) || (activeType === TaskType.Read && idx > completedTagIdx),
                      [styles.voiceTag__completed]:
                        (activeType === TaskType.VoiceTranslate && idx <= completedTagIdx) ||
                        isCompleted ||
                        [TaskType.Listen, TaskType.Read].includes(activeType),
                      [styles.voiceTag__merged]: !activeTags[idx + 1]?.word || text.includes(`${tag.word}${activeTags[idx + 1].word}`),
                    })}
                    key={`${idx}${tag.word}`}
                  >
                    {tag.word}
                  </span>
                ))}
              </div>
            </div>
            {isCompleted && (
              <Button
                size={"small"}
                type={"text"}
                className={styles.btn__clear}
                icon={<CheckOutlined style={{ fontSize: 20, color: "green" }} />}
              />
            )}

            {!isCompleted && active && [TaskType.Read, TaskType.VoiceTranslate].includes(activeType) && (
              <Popconfirm title={"Дать подсказку?"} onConfirm={() => setCompletedTagIdx((prev) => prev + 1)}>
                <Button size={"small"} type={"text"} className={styles.btn__clear} icon={<BulbTwoTone style={{ fontSize: 18 }} />} />
              </Popconfirm>
            )}
          </div>
          <div className={styles.translate}>{translate}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ScrollerTaskRow);
