import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { SentenceType, StatusType, StoryType, TaskType } from "App.types";
import { Editable, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { createEditor, Editor, Transforms } from "slate";
import PageLeaf from "./PageLeaf";
import { ignoreTags, STATIC_URL, successMessage } from "App.constants";
import { delay, findIndex, flatten, isNumber, last } from "lodash";
import { Button, Dropdown, Image, message, notification, Skeleton } from "antd";
import { scroller } from "react-scroll";
import { getCompletedPageSlate, getMaskedSlateForPage } from "./Page.helpers";
import PageElement from "./PageElementAndBlock";
import { AudioOutlined, BookOutlined, BulbOutlined, PauseOutlined, QuestionOutlined, SoundFilled } from "@ant-design/icons";
import { isEqualEnglishWords, isEqualText, withCustomLogic, withCustomMaskedLogic } from "App.helpers";
import Dictionary from "Components/Dictionary";
import API from "Api";
import PageMaskedLeaf from "./PageMaskedLeaf";
import PageMaskedElement from "./PageMaskedElement";
import StorySentence from "Components/StorySentence";
import { isMobile } from "react-device-detect";
import styles from "./PageLesson.module.scss";

import "./Page.css";
import useDeepgramAlt from "Hooks/useDeepgramAlt";

export type CustomText = {
  word: string;
  text: string;
  lemma: string;
  answer: string;
  id?: number;
  sentIdx: number;
  status?: string;
  idx: number;
  isMasked?: boolean;
};

type Props = {
  lessonId: number;
  page: number;
  audio?: HTMLAudioElement;
  sentences?: SentenceType[];
  activeType: TaskType;
  title?: string;
  play?: Function;
  // progressId: number | undefined;
  onNext: Function;
  source: string;
  story: StoryType;
};

const PageLesson: FC<Props> = ({ story, source, onNext, audio, sentences = [], activeType, title, page, lessonId }) => {
  const [status, setStatus] = useState<StatusType>(StatusType.Error);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  // const [statuses, setStatuses] = useState<{ [idx: number]: StatusType }>({});
  const [, setScore] = useState(0);
  const [isDictOpened, setDictOpened] = useState(false);
  const [activeSent, setActiveSent] = useState<number>();
  const [activeLeaf, setActiveLeaf] = useState<number>(-1);
  const [initialValue, setInitialValue] = useState<any>();

  useEffect(() => {
    audio?.addEventListener("play", () => setAudioStatus(StatusType.isPlaying));
    audio?.addEventListener("pause", () => setAudioStatus(StatusType.Error));
    audio?.addEventListener("ended", () => setAudioStatus(StatusType.Completed));

    return () => audio?.pause();
  }, [audio]);

  const allTags = useMemo(() => flatten(sentences.map((s) => s.tags)), [sentences]);

  const editor = useMemo(
    () =>
      [TaskType.Masked].includes(activeType)
        ? withReact(withCustomMaskedLogic(createEditor(), true))
        : withReact(withHistory(withCustomLogic(createEditor()))),

    [activeType],
  );

  const sentence = useMemo(() => (isNumber(activeSent) ? sentences[activeSent] : undefined), [sentences, activeSent]);

  const [notifyApi, contextHolder] = notification.useNotification({ placement: "bottom" });
  const [messageApi, messageContextHolder] = message.useMessage();

  // initial slate
  useEffect(() => {
    setActiveSent(undefined);

    if ([TaskType.Read, TaskType.Listen].includes(activeType)) {
      // editor.children.forEach(() => {
      //   Transforms.delete(editor, { at: [0] });
      // });
      // editor.children = [];

      setInitialValue([{ children: getCompletedPageSlate(sentences, source) }]);
      // Transforms.insertNodes(editor, getCompletedPageSlate(sentences, source));
    } else {
      setStatus(StatusType.Error);

      // Transforms.insertNodes(editor, getMaskedSlateForPage(sentences));
      setInitialValue([{ children: getMaskedSlateForPage(sentences, isMobile), type: "parent" }]);
    }
  }, [editor, sentences, activeType, source]);

  const renderMaskedLeaf = useCallback(
    (props: any) => (
      <PageMaskedLeaf
        completed={status === StatusType.Completed}
        wrong={
          status === "error" && activeType === "read" && props.leaf.idx === (activeLeaf ?? 0) + 1 && !ignoreTags.includes(props.leaf.text)
        }
        underlined={
          (status === "completed" && activeType === "read") ||
          (props.leaf.idx <= (activeLeaf ?? -1) && activeType === "read") ||
          (props.leaf.idx <= (activeLeaf ?? -1) && props.leaf.sentIdx === activeSent)
        }
        showErrors={!["editing", "completed", "loading", "isRecording", ""].includes(status)}
        {...props}
      />
    ),
    [status, activeLeaf, activeType, activeSent],
  );

  const renderLeaf = useCallback(
    (props: any) => {
      return (
        <PageLeaf active={props.leaf.sentIdx === activeSent && props.leaf.idx <= activeLeaf} showErrors={status !== "editing"} {...props} />
      );
    },
    [status, activeLeaf, activeSent],
  );

  const {
    start,
    stop,
    reset,
    transcript: speechText,
  } = useDeepgramAlt({ tags: allTags, setStatus, text: sentences[activeSent ?? 0]?.text });
  const words = speechText.split(" ");

  const cleanedText = useMemo(() => sentence?.text.replace(/[^a-zа-яё0-9]/gi, "").toLowerCase(), [sentence?.text]);
  const cleanedSpeechText = useMemo(() => speechText.replace(/[^a-zа-яё0-9]/gi, "").toLowerCase(), [speechText]);

  useEffect(() => {
    if (!cleanedSpeechText || !cleanedText || !sentence) return;

    if (isEqualText(cleanedSpeechText, cleanedText) || cleanedSpeechText.includes(cleanedText)) {
      setActiveLeaf(sentence.transcripts.length - 1);
      reset();
      delay(() => {
        setActiveSent((prevState = 0) => prevState + 1);
        setActiveLeaf(-1);
      }, 1000);
    } else {
      sentence.transcripts.every((tr, idx: number) => {
        const isRight = isEqualEnglishWords(words[idx], tr.text);

        if (isRight) {
          setActiveLeaf(idx);
        }
        return isRight;
      });
    }
  }, [sentence, cleanedSpeechText, cleanedText, sentence?.transcripts, words, reset]);

  // process
  useEffect(() => {
    if (status === StatusType.Completed) {
      API.progress.save({ lesson: { id: lessonId }, status: "progress", type: activeType, page, percent: 1 });
    }
  }, [status, lessonId, activeType, page]);

  const maskedCheck = useCallback(async () => {
    const maskedNodes = Editor.nodes<CustomText>(editor, {
      match: (n) => n.isMasked,
      at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) },
    });

    const answers = [];
    for (const [node, maskedPath] of maskedNodes) {
      answers.push({ ...node, path: maskedPath });
    }
    const spellResults = await API.speller.get(answers);
    const lemmas = (await API.lemma.get(answers.map((el) => el.text).join(","))).filter((el) => el.word !== ",");

    let hasErrors = false;
    answers.forEach((child, idx) => {
      let wordStatus = "";
      if (isEqualText(child.text, child.answer)) {
      } else if (child.isMasked && child.text === "") {
        wordStatus = "empty";
      } else if (spellResults[idx][0]?.s.includes(child.answer) || spellResults[idx][0]?.s.includes(child.lemma)) {
        notifyApi.error({ message: <b>{child.text}</b>, description: "слово содержит опечатки" });
        wordStatus = "typo";
      } else if (isEqualText(lemmas[idx]?.lemma, child.lemma)) {
        notifyApi.warning({ message: <b>{child.text}</b>, description: "неверная форма слова" });
        wordStatus = "lemma";
      } else {
        wordStatus = "wrong";
      }
      if (wordStatus) {
        setStatus(StatusType.Error);
        hasErrors = true;
      }
      Transforms.setNodes(editor, { status: wordStatus }, { at: child.path });
    });

    if (!hasErrors) {
      setStatus(StatusType.Completed);
      messageApi.success(successMessage.message);
    } else {
      messageApi.error({ content: "Есть ошибки!" });
    }

    return hasErrors;
  }, [notifyApi, editor, messageApi]);

  const onMaskedHint = async () => {
    await maskedCheck();
    // API.event.save({ text, type: "hint", task: activeType, lesson: { id: lessonId }, sentence });
    const maskedNodes = Editor.nodes<CustomText>(editor, {
      match: (n) => n.isMasked && n.status !== "",
      at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) },
    });

    for (const [node, maskedPath] of maskedNodes) {
      Transforms.insertText(editor, node.answer || "", { at: maskedPath });
      Transforms.setNodes(editor, { status: "hint" }, { at: maskedPath });

      delay(() => Transforms.setNodes(editor, { status: "" }, { at: maskedPath }), 2000);
      setScore((prevState) => prevState && prevState - 10);

      return;
    }
  };

  const play = (
    from: number = sentences[0].transcripts[0].start,
    to: number = last(last(sentences)?.transcripts)?.end || 0,
    playOne: boolean = false,
  ) => {
    if (!audio) return;
    audio.currentTime = !audio.currentTime || audio.currentTime >= to || playOne ? from : audio.currentTime;
    audio.play();

    audio.ontimeupdate = ({ target: { paused } }: any) => {
      const { currentTime } = audio;
      // console.log("onTimeUpdate", currentTime, paused);

      if (currentTime >= to || paused) {
        audio.pause();
        return delay(() => {
          if (playOne && activeType !== TaskType.Read) setActiveSent(undefined);
          if (!paused && !playOne && currentTime) {
            setActiveSent(sentences.length);
            setStatus(StatusType.Completed);
          }
          setActiveLeaf(-1);
        }, 500);
      }

      let activeIdx = sentences.findIndex(
        ({ transcripts }) => transcripts[0]?.start <= currentTime && (last(transcripts)?.end || 0) >= currentTime,
      );
      if (activeIdx === -1 && !playOne) {
        activeIdx = sentences.findIndex(({ transcripts }) => transcripts[0]?.start >= currentTime);
      }

      if (activeIdx >= 0) {
        setActiveSent(activeIdx);
        const wordIdx = findIndex(sentences[activeIdx].transcripts, (el) => el.end >= currentTime);
        setActiveLeaf(wordIdx);
      }
    };
  };

  const onKeyDownMasked: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { selection } = editor;
    // const node = selection && Editor.node(editor, selection);

    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      const next = Editor.next(editor, { at: selection?.focus });
      const nextMaskedNodes = Editor.nodes<CustomText>(editor, {
        match: (n) => n.isMasked,
        at: { anchor: Editor.start(editor, next?.[1] || []), focus: Editor.end(editor, []) },
      });

      for (const [nextMaskedNode, nextMaskedPath] of nextMaskedNodes) {
        //scroller.scrollTo(`${nextMaskedNode.sentIdx}`, { offset: -200, smooth: true, duration: 1000 });

        return Transforms.setSelection(editor, {
          anchor: { path: nextMaskedPath, offset: nextMaskedNode.text.length },
          focus: { path: nextMaskedPath, offset: nextMaskedNode.text.length },
        });
      }

      maskedCheck();
    }
  };

  const onClickRecording = () => {
    if ([StatusType.IsRecording, StatusType.Loading].includes(status)) {
      stop();
    } else {
      setStatus(StatusType.Loading);
      setActiveLeaf(-1);
      setActiveSent((prev) => prev ?? 0);
      start();
    }
  };

  useEffect(() => {
    if (activeType === TaskType.Listen && activeSent && activeSent < sentences.length)
      scroller.scrollTo(`${activeSent}`, { offset: -200, smooth: true, duration: 1000 });
  }, [activeSent, activeType, sentences.length]);

  console.log(activeSent);

  return (
    <div className={styles.pageLesson}>
      {isMobile ? (
        <div className={styles.page__text}>
          {initialValue ? (
            <Slate editor={editor} initialValue={initialValue}>
              <Editable
                className={styles.textArea}
                readOnly={status === "completed" || ["listen", "read", "select"].includes(activeType)}
                onKeyDown={onKeyDownMasked}
                renderLeaf={activeType === TaskType.Masked ? renderMaskedLeaf : renderLeaf}
                renderElement={(props) =>
                  activeType === TaskType.Masked ? (
                    <PageMaskedElement isActive={activeSent === props.element.idx} play={play} {...props} />
                  ) : (
                    <PageElement
                      showTranslateInTooltip={source === "Оригинал"}
                      showText={source !== "Все вместе"}
                      isActive={activeSent === props.element.idx}
                      play={play}
                      {...props}
                    />
                  )
                }
              />
            </Slate>
          ) : (
            <Skeleton loading />
          )}
          <Image
            onError={(e: any) => (e.target.style.display = "none")}
            preview={{ toolbarRender: () => null }}
            src={`${STATIC_URL}/stories/${title}/images/image ${page}.jpg`}
          />
        </div>
      ) : (
        // desktop view
        <div className={styles.container}>
          <div className={styles.page__text}>
            {initialValue ? (
              <Slate editor={editor} initialValue={initialValue}>
                <Editable
                  id={"sentences"}
                  className={styles.textArea}
                  readOnly={status === "completed" || ["listen", "read", "select"].includes(activeType)}
                  onKeyDown={onKeyDownMasked}
                  renderLeaf={activeType === TaskType.Masked ? renderMaskedLeaf : renderLeaf}
                  renderElement={(props) =>
                    activeType === TaskType.Masked ? (
                      <PageMaskedElement setActiveSent={setActiveSent} play={play} {...props} />
                    ) : (
                      <PageElement
                        onClick={setActiveSent}
                        showText={false}
                        isActive={activeSent === props.element.idx}
                        play={play}
                        {...props}
                      />
                    )
                  }
                />
              </Slate>
            ) : (
              <Skeleton loading />
            )}

            <Image
              onError={(e: any) => (e.target.style.display = "none")}
              preview={false}
              src={`${STATIC_URL}/stories/${title}/images/image ${page}.jpg`}
            />
          </div>

          <div>
            {sentences.map((sentence, idx) => (
              <Fragment key={sentence.id}>
                <StorySentence
                  // activeWordIdx={activeSent === sentence.id ? activeWordIdx : null}
                  active={activeSent === idx}
                  play={play}
                  sentence={sentence}
                  marginLeft={idx === 0 || sentences[idx - 1].wrap}
                  showText={false}
                  onClick={() => setActiveSent(idx)}
                  showTranslate
                />
                {sentences[idx]?.wrap && <div style={{ height: 4 }} />}
              </Fragment>
            ))}
          </div>
        </div>
      )}

      <div className={styles.panel}>
        <div className={styles.panel__content}>
          {status === "completed" ? (
            <>
              {audioStatus === StatusType.isPlaying ? (
                <Button icon={<PauseOutlined />} onClick={() => audio?.pause()} />
              ) : (
                <Button icon={<SoundFilled />} onClick={() => play()} />
              )}
              <Button className={styles.btn_next} type={"primary"} shape={"round"} onClick={() => onNext()}>
                Далее
              </Button>
              <BookOutlined className={styles.btn_play} onClick={() => setDictOpened(true)} />
            </>
          ) : (
            <>
              {activeType === "masked" && (
                <>
                  <Button style={{ visibility: "hidden", width: 55 }} />
                  <Button type={"primary"} shape={"round"} onClick={maskedCheck}>
                    проверить
                  </Button>
                  <Dropdown
                    menu={{
                      items: [
                        { key: 0, label: "словарь", icon: <BookOutlined />, onClick: () => setDictOpened(true) },
                        {
                          key: 1,
                          label: "подсказать",
                          icon: <BulbOutlined />,
                          onClick: onMaskedHint,
                        },
                      ],
                    }}
                    trigger={["click"]}
                  >
                    <Button icon={<QuestionOutlined />}></Button>
                  </Dropdown>
                </>
              )}
              {activeType === "listen" && (
                <>
                  <Button style={{ visibility: "hidden" }} />

                  {audioStatus === StatusType.isPlaying ? (
                    <Button icon={<PauseOutlined />} onClick={() => audio?.pause()} />
                  ) : (
                    <Button
                      icon={<SoundFilled />}
                      onClick={() => {
                        play();
                      }}
                    />
                  )}
                  <BookOutlined className={styles.btn_play} onClick={() => setDictOpened(true)} />
                </>
              )}
              {activeType === "read" && (
                <>
                  <Button style={{ visibility: "hidden" }} />

                  <Button
                    onClick={onClickRecording}
                    className={styles.record}
                    type={"primary"}
                    icon={<AudioOutlined />}
                    shape="circle"
                    data-recording={status === "isRecording"}
                    loading={status === "loading"}
                  />
                  <Button style={{ visibility: "hidden" }} />
                </>
              )}
            </>
          )}
        </div>
      </div>
      {/*<div className={styles.placeholder} />*/}
      {contextHolder}
      {messageContextHolder}
      <Dictionary storyId={story.id} isOpen={isDictOpened} sentences={sentences} toggle={setDictOpened} />
    </div>
  );
};

export default PageLesson;
