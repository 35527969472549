import { Button, Drawer, Flex } from "antd";
import { isMobile } from "react-device-detect";
import React, { FC, PropsWithChildren, useContext } from "react";
import useTextTemplatesTooltip from "Hooks/useTextTemplatesTooltip";
import styles from "./HintDrawer.module.scss";
import { replaceTemplates } from "App.helpers";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { UserContext } from "App";

const HintDrawer: FC<
  PropsWithChildren & {
    text: any;
    open: boolean;
    onClose?: () => any;
    buttonText?: string;
  }
> = ({ children, onClose, buttonText = "OK", text, open }) => {
  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  const renderText = useTextTemplatesTooltip();
  const html = renderText(replaceTemplates(text, userProps), styles.tooltip);

  return (
    <Drawer closable={false} height={"auto"} onClose={onClose} mask={false} placement="bottom" open={open}>
      <Flex justify={"center"}>
        <div style={{ width: "100%", maxWidth: 1060 }}>
          <Flex justify={"space-between"} style={{ flexDirection: isMobile ? "column" : undefined }}>
            <div className={styles.hintHtml} style={{ marginBottom: isMobile ? 10 : 0 }}>
              {html}
            </div>
            {children || (
              <Button size={isMobile ? "middle" : undefined} type={"primary"} onClick={onClose}>
                {buttonText}
              </Button>
            )}
          </Flex>
        </div>
      </Flex>
    </Drawer>
  );
};

export default HintDrawer;
