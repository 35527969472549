import { useCallback, useContext } from "react";
import parse, { domToReact } from "html-react-parser";
import { Tooltip } from "antd";
import { replaceTemplates } from "App.helpers";
import { useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { UserContext } from "App";

const useTextTemplatesTooltip = () => {
  const user = useContext(UserContext);

  const { data: userProps } = useSuspenseQuery({
    queryKey: ["props", user?.id],
    queryFn: API.user.getProps,
    staleTime: Infinity,
  });

  return useCallback(
    (text: string | undefined, className?: string) =>
      parse(text ?? "", {
        replace: (node: any) => {
          if (node.name === "a") {
            return (
              <Tooltip className={className} title={replaceTemplates(node.attribs["href"], userProps)}>
                {domToReact(node.children)}
              </Tooltip>
            );
          }
        },
      }),
    [userProps],
  );
};

export default useTextTemplatesTooltip;
