import { delay, round } from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SentenceType, StatusType, TaskType } from "App.types";
import Dictionary from "Components/Dictionary";
import GrammarPanel from "Components/GrammarPanel";
import Slider from "react-slick";
import styles from "./IntroLesson.module.scss";
import { Button, Flex, Popconfirm, Progress, Segmented } from "antd";
import { AudioOutlined, EditOutlined, RollbackOutlined } from "@ant-design/icons";
import HintDrawer from "Components/HintDrawer";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import API from "Api";
import { useNavigate, useParams } from "react-router-dom";
import useSentenceAudio from "Hooks/useSentenceAudio";
import ButtonClose from "Components/ButtonClose";
import CompleteWindow from "Components/CompleteWindow";
import useProgress from "Hooks/useProgress";
import ReportFeedback from "Components/ReportFeedback";
import RecordButton from "Components/RecordButton";
import SentenceTaskHelpButton from "Components/SentenceTask/SentenceTaskHelpButton";
import TaskPanelBasic from "Components/TaskPanelBasic";
import { useIsBeginner } from "Hooks/useIsBeginner";
import ScrollerTaskRow from "Components/SentenceTask/ScrollerTask/ScrollerTaskRow";
import ScrollerTaskTranslate from "Components/SentenceTask/ScrollerTask/ScrollerTaskTranslate";
import useSocketSpeech from "Hooks/useSocketSpeech";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const IntroLessonNew: FC<{}> = () => {
  const [isDictOpened, setDictOpened] = useState(false);
  const [showGrammar, setShowGrammar] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const [lessonStatus, setLessonStatus] = useState<StatusType>(StatusType.Empty);
  const [audioStatus, setAudioStatus] = useState<StatusType>(StatusType.Empty);
  const [hintDrawerStatus, setHintDrawerStatus] = useState<"closed" | "active" | "">();
  const [sentModes, setSentModes] = useState<boolean[]>([]);

  const { id = "", course, storyId = "" } = useParams();

  const isBeginner = useIsBeginner();

  const { data: lesson } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["lesson", id],
    queryFn: () => API.lesson.get(id),
  });

  const { data: sentences } = useSuspenseQuery({
    staleTime: Infinity,
    queryKey: ["sentences", storyId],
    queryFn: () => API.sentence.getListByStoryId(storyId),
  });

  const sliderRef = useRef<Slider>(null);

  const queryClient = useQueryClient();

  const onRepeat = useCallback(() => {
    // setCompletedTagIdx(-1);

    API.progress.delete(id).then(() => {
      queryClient.invalidateQueries({ queryKey: ["progress", +id] });
    });

    queryClient.setQueryData(["progress", +id], []);

    setLessonStatus(StatusType.Empty);
  }, [id, queryClient]);

  const { progress, contextHolder } = useProgress({ lesson: lesson, onRepeat });

  const completedCards = useMemo(() => sentences?.filter((s) => progress?.some((pr) => pr.sentence.id === s.id)), [progress, sentences]);

  const tasksToComplete = useMemo<any>(() => {
    return sentences.filter((s) => !completedCards?.some((completed) => completed?.id === s?.id));
  }, [sentences, completedCards]);

  const allTasks = useMemo(
    () => [
      ...completedCards.map((s) => ({ ...s, completed: true })),
      ...tasksToComplete.map((s: SentenceType) => ({ ...s, task: s.tasks[0] })),
    ],
    [completedCards, tasksToComplete],
  );

  useEffect(() => {
    setSentModes(allTasks.map(() => false));
  }, [allTasks]);

  const activeSentence = useMemo<SentenceType>(() => allTasks[activeIdx], [allTasks, activeIdx]);

  const {
    start,
    stop,
    reset,
    isSpeaking,
    results: speechResults,
  } = useSocketSpeech({
    tags: activeSentence.tags,
    text: activeSentence.text,
    setStatus: setAudioStatus,
    interim_results: true,
  });

  useEffect(() => {
    if (allTasks[activeIdx]?.hint) {
      setHintDrawerStatus((prev) => (prev === "closed" ? prev : "active"));
    } else {
      setHintDrawerStatus("");
    }
  }, [activeIdx, allTasks]);

  const audio = useSentenceAudio({ sentence: activeSentence });

  const onTaskComplete = useCallback(() => {
    reset();

    const result = {
      sentence: { id: activeSentence?.id || 0 },
      lesson: { id: +id },
      type: activeSentence?.task || TaskType.Translate,
      tries: 1,
    };
    API.progress.save(result);

    if (activeIdx >= allTasks.length - 1) {
      stop();

      API.progress.save({
        lesson: { id: +id },
        percent: 100,
        status: "completed",
      });

      return setLessonStatus(StatusType.Completed);
    } else {
      delay(() => {
        sliderRef.current?.slickNext();
        setActiveIdx((prev) => prev + 1);
      }, 1000);
    }
  }, [activeIdx, activeSentence?.id, activeSentence?.task, allTasks.length, id, reset, stop]);

  const onRecordClick = useCallback(() => {
    if (audioStatus === StatusType.IsRecording) {
      stop();
    } else {
      // setCompletedSpeech("");
      setAudioStatus(StatusType.Loading);
      start();
    }
  }, [audioStatus, start, stop]);

  const percent = useMemo(() => {
    const tasksCount = allTasks.length;
    return round((100 / tasksCount) * activeIdx, 2);
  }, [activeIdx, allTasks.length]);

  const navigate = useNavigate();

  const onCheckRef = useRef<Function>(null);

  return (
    <div className={styles.introLesson}>
      {lessonStatus === "completed" ? (
        <CompleteWindow onRepeat={onRepeat} lessonId={+id} course={course} />
      ) : (
        <>
          <div className={styles.header}>
            <Flex>
              <Progress percent={round(percent)} showInfo={false} />
              <ButtonClose path={course ? `/course/${course}` : "/"} />
            </Flex>
          </div>

          <Popconfirm
            okText={"Yes"}
            cancelText={"No"}
            title={"Переходим в урок вместо того, чтобы выполнить это экспресс-задание?"}
            onConfirm={() => navigate(`/course/${course}/lessons/exercise/${id}`)}
          >
            <Button icon={<RollbackOutlined />} type={"link"}>
              Пройти этот урок
            </Button>
          </Popconfirm>

          <Segmented
            size={"large"}
            value={sentModes[activeIdx]}
            onChange={(value) => {
              stop();
              setSentModes((prev) => prev.map((m, idx) => (idx >= activeIdx ? value : m)));
            }}
            options={[
              { icon: <AudioOutlined style={{ fontSize: 21 }} />, label: "speak", value: false },
              { value: true, label: "write", icon: <EditOutlined style={{ fontSize: 21 }} /> },
            ]}
          />

          <Slider infinite={false} centerPadding={"10px"} ref={sliderRef} centerMode vertical slidesToShow={3} slidesToScroll={1}>
            {allTasks.map((t: any, idx: number) =>
              sentModes[idx] ? (
                <ScrollerTaskTranslate
                  active={idx === activeIdx}
                  key={idx}
                  audio={idx === activeIdx ? audio : undefined}
                  sentence={t}
                  activeType={t.task}
                  lesson={lesson}
                  onNext={onTaskComplete}
                  onCheckRef={onCheckRef}
                />
              ) : (
                <ScrollerTaskRow
                  key={idx}
                  audio={idx === activeIdx ? audio : undefined}
                  sentence={t}
                  activeType={TaskType.VoiceTranslate}
                  lesson={lesson}
                  active={idx === activeIdx}
                  speechResults={speechResults}
                  isRecording={audioStatus === StatusType.IsRecording}
                  onComplete={onTaskComplete}
                />
              ),
            )}
            <div></div>
            <div></div>
          </Slider>
        </>
      )}

      {lessonStatus !== StatusType.Completed && (
        <TaskPanelBasic>
          <ReportFeedback lessonId={lesson.id} sentId={activeSentence?.id} task={activeSentence?.task} storyId={storyId} />
          {sentModes[activeIdx] ? (
            <Button shape={"round"} type={"primary"} onClick={() => onCheckRef.current?.()}>
              {isBeginner ? "проверить" : "check"}
            </Button>
          ) : (
            <RecordButton
              onClick={onRecordClick}
              isRecording={audioStatus === "isRecording"}
              loading={audioStatus === "loading"}
              isSpeaking={isSpeaking}
            />
          )}

          <SentenceTaskHelpButton storyId={storyId} openDictionary={setDictOpened} showGrammar={setShowGrammar} />
        </TaskPanelBasic>
      )}

      <HintDrawer text={allTasks[activeIdx]?.hint} open={hintDrawerStatus === "active"} onClose={() => setHintDrawerStatus("closed")} />
      <Dictionary storyId={lesson.storyId} isOpen={isDictOpened} sentences={sentences} toggle={setDictOpened} />
      <GrammarPanel isOpen={showGrammar} grammar={lesson?.grammar} toggle={setShowGrammar} />
      {contextHolder}
    </div>
  );
};

export default IntroLessonNew;
